<template>
  <div class="box">
    <div class="headline">题库设置</div>
    <div class="second_line">4.1 岗位职能表</div>
    <span class="text">
      进入岗位职能表维护界面，【题库设置】→【岗位职能表】，如下图
      请注意，岗位职能表是非常重要的功能，关系到文件检查是否能抽到题目，
      也关系到抽出来题目的准确率。修改此表需要非常谨慎
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/1.png')"
      :preview-src-list="[require('../../assets/Bankimg/1.png')]"
    >
    </el-image>
    <span class="text"> 选中某一行需要修改的数据后，点击上图中修改按键 </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/2.png')"
      :preview-src-list="[require('../../assets/Bankimg/2.png')]"
    >
    </el-image>
    <span class="text">
      公司部门是不能修改的，对应的岗位、职能、文件编号、文件名称和上级公司标识
      都是可以根据需要修正。 完成后 点击确定即可
      注意的是，修改完成后，可能有几秒钟的生效时间。
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/3.png')"
      :preview-src-list="[require('../../assets/Bankimg/3.png')]"
    >
    </el-image>
    <span class="text">
      删除岗位职能表时，可以选中一条或多条，然后点击“批量删除”完成删除操作
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      srcList: "require('../../assets/Bankimg/1.jpg')",
    };
  },
};
</script>

<style  lang="less" scoped>
.box {
  padding-left: 40px;
  .headline {
    font-size: 28px;
    color: #1f2d3d;
    padding-top: 28px;
    line-height: 45px;
    padding-left: 0px ;
  }
  .second_line {
    font-size: 24px;
    line-height: 32px;
    padding: 24px 0 12px;
  }
  .text {
    color: #141e31;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 8px;
  }
  img {
  }
}
</style>